.icon_navMenu svg,
.MainPage_NavBar svg {
    width: 25px;
    height: 25px;
}

.MainPage_NavBar svg {
    margin-right: -3px;

}

.NavBar_wrapper {
    grid-row: 1/ span 3;


}

.NavBar_wrap {
    background: #142b4b;
    height: 100%;
    padding: 12px 0;
    box-shadow: 2px 4px 4px rgba(0, 0, 0 , .25)
}

.NavBar_tree.ant-menu {
    background: inherit;
    color: white;
    width: 100%;
    overflow: auto;
    max-height: 95%;
}
.NavBar_tree .ant-menu-submenu-title{
display: flex;
    align-items: center;
}
.NavBar_item  .ant-menu .ant-menu-submenu .ant-menu-submenu-title{
    display: flex;
    align-items: center;
}
.NavBar_tree .ant-menu-item{
    display: flex;
    align-items: center;
}
.dark-theme svg,
.darkTheme svg {
    fill: white;
}

.NavBar_wrapper.collapse {
    width: 100%;
}

.NavMenu_logo_item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.NavMenu_logo_item.active {
    width: 150px;
    transition: .1s ease-out;
    transition-delay: .1s;
}

.NavMenu_logo_item.noActive {
    width: 0;
    transition: .1s ease-out;
    transition-delay: 0.1s;
}

.NavMenu_logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 10px;
    height: 50px;
}

.NavMenu_logo_item svg {
    padding-top: 10px;
    width: 100%;

}

.NavMenu_btn svg {
    width: 25px;
    height: 25px;
}

.darkTheme.ant-menu.ant-menu-dark,
.darkTheme.ant-menu-dark .ant-menu-sub,
.darkTheme.ant-menu.ant-menu-dark .ant-menu-sub {
    background: #142b4b;
    color: white;
}

.NavBar_tree.dark.ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: #142b4b;
    color: white;

}

.icon_navMenu svg,
.NavBar_item .ant-menu-item {
    display: flex;
    align-items: center;
    align-self: center;
}

.icon_navMenu.ant-menu-item-icon {
    display: flex;
    align-items: center;
}

.NavBar_item.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected,
.NavBar_tree.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: hsla(0, 0%, 100%, .18823529411764706);
    color: #fff;
    display: flex;
    align-items: center;
}

.NavBar_wrapper.noCollapse {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-template-rows: 100%;
    width: 100%;
    position: absolute;
    height: 100%;
}


.NavMenu_btn.ant-btn-primary {
    background: inherit;
    border: none;
    width: max-content;
    box-shadow: none;
}

.navigation_bar  .ant-menu-inline .ant-menu-item.double_ant{
    padding-left: 48px;

}
